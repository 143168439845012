import { BaseEntity, IBaseEntity } from '@/bundles/core/model';

export interface ISize extends IBaseEntity {
  name?: string;
  value?: string;
  productId?: string;
}

export class Size extends BaseEntity implements ISize {
  name?: string = '';
  value?: string = '';
  productId?: string;

  constructor(arg: Record<string, unknown>) {
    super(arg);
    this.name = (arg?.name as string) || '';
    this.value = (arg?.value as string) || this.name;
    this.productId = arg?.productId as string;
  }
}

import { BaseEntity, IBaseEntity } from '@/bundles/core/model';
import { IImage, Image } from '@/bundles/core/model/image';

import { Category, ICategory } from './category';
import { Collection, ICollection } from './collection';
import { Color, IColor } from './color';
import { ILine, Line } from './line';
import { ISize, Size } from './size';
import { ITag, Tag } from './tag';

export interface IPrice {
  id?: string;
  productId?: string;
  public?: {
    currency?: string;
    value: number;
    factor?: number;
  };
  mayor?: {
    currency?: string;
    value: number;
    factor?: number;
  };
  franchise?: {
    currency?: string;
    value: number;
    factor?: number;
  };
}

export interface IQuantity {
  id?: string;
  stock?: {
    value?: number;
  };
  available?: {
    value?: number;
  };
  minimum?: {
    value?: number;
  };
  productId?: string;
}

export interface IProduct extends IBaseEntity {
  name?: string;
  sku?: string;
  barcode?: string;
  status?: string;
  description?: string;
  collection?: ICollection;
  collectionId?: string;
  line?: ILine;
  lineId?: string;
  category?: ICategory;
  categoryId?: string;
  quantity?: IQuantity;
  quantityId?: string;
  price?: IPrice;
  priceId?: string;
  currency?: string;
  categories?: Array<ICategory>;
  colors?: Array<IColor>;
  sizes?: Array<ISize | string>;
  tags?: Array<ITag>;
  height?: number;
  width?: number;
  length?: number;
  weight?: number;
  volume?: number;
  backorder?: boolean;
  thumbnail?: IImage | { url?: string } | string;
  gallery?: Array<IImage | { url?: string } | string>;
  related?: Array<string | { productId: string }>;
}

export class Quantity extends BaseEntity implements IQuantity {
  stock = { value: 0 };
  available = { value: 0 };
  minimum = { value: 0 };

  constructor(arg: Record<string, unknown>) {
    super(arg);
    this.stock = { value: Number((arg?.stock as { value: number })?.value) || 0 };
    this.available = { value: Number((arg?.available as { value: number })?.value) || 0 };
    this.minimum = { value: Number((arg?.minimum as { value: number })?.value) || 0 };
  }
}

export class Price extends BaseEntity implements IPrice {
  public: { currency?: string; value: number; factor?: number } = { currency: 'ARS', value: 0, factor: 1 };
  mayor: { currency?: string; value: number; factor?: number } = { currency: 'ARS', value: 0, factor: 0.5 };
  franchise: { currency?: string; value: number; factor?: number } = {
    currency: 'ARS',
    value: 0,
    factor: 0.3
  };

  constructor(arg: Record<any, unknown>) {
    super(arg);
    this.public = { currency: 'ARS', value: Number((arg?.public as { value: number })?.value) || 0, factor: Number((arg?.public as { factor: number })?.factor) || 1 };
    this.mayor = { currency: 'ARS', value: Number((arg?.mayor as { value: number })?.value) || 0, factor: Number((arg?.mayor as { factor: number })?.factor) || 0.5 };
    this.franchise = { currency: 'ARS', value: Number((arg?.franchise as { value: number })?.value) || 0, factor: Number((arg?.franchise as { factor: number })?.factor) || 0.3 };
  }

  toFormData() {
    let data: { id?: string; public: { factor?: number }; mayor: { factor?: number }; franchise: { factor?: number } } = Object.assign({}, this);
    delete data.id;
    delete data.public?.factor;
    delete data.mayor.factor;
    delete data.franchise.factor;
    return data;
  }
}

export class Product extends BaseEntity implements IProduct {
  status?: string = 'pendiente';
  name?: string = '';
  sku?: string = '';
  barcode?: string = '';
  backorder?: boolean = false;
  collection?: ICollection = new Collection({});
  collectionId?: string | undefined = '';
  lineId?: string | undefined = '';
  line?: ILine = new Line({});
  category?: ICategory = new Category({});
  categoryId: string | undefined = '';
  thumbnail?: IImage | string;
  gallery?: Array<IImage | string> = [];
  description: string = '';
  weight: number = 0;
  volume: number = 0;
  width: number = 0;
  height: number = 0;
  length: number = 0;
  sizes?: Array<ISize | string>;
  colors?: Array<IColor> = [];
  tags?: Array<ITag> = [];
  categories?: Array<ICategory> = [];
  quantity?: IQuantity = new Quantity({});
  price?: IPrice = new Price({});
  related?: Array<string> | Array<{ productId: string }> = [];

  constructor(data: Record<string, unknown> & IProduct) {
    super(data);

    this.name = data.name || '';
    this.sku = data.sku || '';
    this.barcode = data.barcode || '';
    this.status = data.status || 'pendiente';
    this.description = data.description || '';
    this.weight = data.weight || 0;
    this.volume = data.volume || 0;
    this.width = data.width || 0;
    this.height = data.height || 0;
    this.length = data.length || 0;
    this.backorder = data.backorder || false;

    this.thumbnail = typeof data?.thumbnail === 'string' ? new Image({ url: data.thumbnail }) : new Image(data.thumbnail || { url: '' });

    this.gallery = data?.gallery?.map((image) => (typeof image === 'string' ? new Image({ url: image }) : new Image(image as Record<string, unknown>)));

    this.collection = data?.collection ? new Collection(data.collection as Record<string, unknown>) : this.collection;
    this.collectionId = this.collection?.id;
    this.line = data?.line ? new Line(data.line as Record<string, unknown>) : this.line;
    this.lineId = this.line?.id;
    this.category = data?.category ? new Category(data.category as Record<string, unknown>) : this.category;
    this.categoryId = this.category?.id;

    this.colors = data.colors?.some((item) => item.name)
      ? data.colors?.map((item) => new Color(item as Record<string, unknown>))
      : (data?.colors as { color: IColor }[])?.map((item) => item.color)?.map((color) => new Color(color as Record<string, unknown>));

    this.sizes = data?.sizes?.some((item) => typeof item === 'string' || item.name)
      ? data?.sizes?.map((item) => (typeof item === 'string' ? { id: item } : item))
      : (data?.sizes as { size: ISize }[])?.map((item) => item.size).map((size) => new Size(size as Record<string, unknown>));

    this.categories = data?.categories?.some((item) => item.name)
      ? data?.categories?.map((item) => new Category(item as Record<string, unknown>))
      : (data?.categories as { category: ICategory }[])?.map((item) => item.category)?.map((category) => new Category(category as Record<string, unknown>));

    this.tags = data?.tags?.map((tag) => new Tag(tag as never));
    this.price = data?.price ? new Price(data?.price as Record<string, unknown>) : this.price;
    this.quantity = data?.quantity ? new Quantity(data.quantity as Record<string, unknown>) : this.quantity;
    this.related = data?.related?.map((related) => (typeof related === 'string' ? { productId: related } : related));
  }

  toFormData() {
    let data = super.toFormData() as IProduct;

    delete data.id;
    delete data.tags;
    delete data.categories;
    delete data.colors;
    delete data.sizes;
    delete data.line;
    delete data.collection;
    delete data.category;

    data.lineId = data.lineId ? data.lineId : undefined;
    data.collectionId = data.collectionId ? data.collectionId : undefined;
    data.categoryId = data.categoryId ? data.categoryId : undefined;
    data.gallery = this.gallery?.map((image) => image?.toString?.()) as Array<string>;
    data.thumbnail = typeof this.thumbnail === 'string' ? this.thumbnail : this.thumbnail?.toString?.();

    return data as Record<string, unknown>;
  }
}

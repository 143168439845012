import { z } from 'zod';

const schemas = z.object({
  email: z.string().max(255),
  name: z.string(),
  username: z.string().max(255).optional(),
  password: z.string().max(255).optional(),
  confirm: z.string().max(255).optional(),
  role: z.string().optional(),
  status: z.string().optional(),
});

export default schemas;

import { v4 as uuidv4 } from 'uuid';
import BaseEntity, { IBaseEntity } from './base-entity';

export interface IImage extends IBaseEntity {
  url?: string;
  path?: string;
  fileName?: string;
  objectUrl?: string;
}

export class Image extends BaseEntity implements IImage {
  url?: string;
  path?: string;
  fileName?: string;
  objectUrl?: string;

  constructor(arg: Record<string, unknown> & IImage) {
    super(arg);
    this.id = (arg?.id as string) || uuidv4();
    this.url = (arg?.url as string) || '';
    this.path = (arg?.path as string) || '';
    this.fileName = (arg?.fileName as string) || this.id || '';
    this.objectUrl = (arg?.objectUrl as string) || '';
  }

  toString() {
    return this.url || '';
  }
}

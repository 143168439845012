import { useFlash } from '@lib/flash';
import LOG from '@lib/logger';
import { objEmpty } from '@lib/utils';
import { useRouter } from 'next/navigation';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';

import EntityManager from '@/bundles/core/services/entity-manager';
import { IUser, User } from '@/bundles/users/model/user';

import { UserFormProps } from '../index';
import useFormFields, { FormValuesProps } from './useFormFields';

export type useUserFormProps = {} & UserFormProps;

export default function useUserForm(props: useUserFormProps) {
  const router = useRouter();
  const flash = useFlash();
  const { fields, defaultValues, resolver } = useFormFields(props);
  const { control, handleSubmit, ...form } = useForm<FormValuesProps>({ defaultValues, resolver });

  const customerManager = useMemo(() => new EntityManager<IUser>('/', '/api/proxy/users'), []);

  const confirm = form.watch('confirm');

  const confirmPassword = (value: Record<string, string>) => {
    if (value.password && value.password !== value.confirm) {
      form.setError('confirm', { type: 'manual', message: 'Las claves no coinciden' });
    }
  };

  useEffect(() => {
    confirmPassword(form.getValues());
  }, [confirm]);

  const onSubmit = async (values: FormValuesProps) => {
    try {
      const newUser = new User(values).toFormData();
      LOG.debug({ msg: 'onSubmit', values, newUser });
      const { error, data: customer } = await customerManager.save({ ...newUser, id: props.user?.id });
      LOG.debug({ msg: 'onSubmit:users', customer, error });
      if (error) {
        flash.toast('No pudimos guardar el User', 'error', error);
        return;
      }
      flash.toast('User guardado', 'success');
      router.refresh();
    } catch (error) {
      LOG.warn({ msg: 'onSubmit', error });
      flash.toast('No pudimos guardar el User', 'error', (error as Error).message);
    }
  };

  useEffect(() => {
    if (!objEmpty(form.formState.errors)) {
      const error = JSON.stringify(form.formState.errors, null, 2);
      flash.toast('Hay errores en el formulario', 'error', error);
      LOG.warn({ msg: 'useUserForm::formState.errors', errors: form.formState.errors });
    }
  }, [form.formState.errors]);

  return { onSubmit, handleSubmit, control, form, fields };
}

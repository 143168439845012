import { IImage } from '@/bundles/core/model/image';
import EntityManager from '@/bundles/core/services/entity-manager';
import LOG from '@lib/logger';
import { isBase64 } from '@lib/utils';

import { Category, ICategory } from '../model/category';
import { Collection, ICollection } from '../model/collection';
import { Color, IColor } from '../model/color';
import { ILine, Line } from '../model/line';
import { IProduct, Product } from '../model/product';
import { ISize, Size } from '../model/size';
import { ITag, Tag } from '../model/tag';

class ProductManager extends EntityManager<IProduct> {
  constructor(baseURL: string, path: string = '/api/proxy/products') {
    super(baseURL, path);
  }

  async addTags(productId: string, tags: Array<ITag>): Promise<unknown> {
    let tagsResponse: unknown = null;
    try {
      const product = await this.getById(productId);
      if (!product) {
        LOG.error({ msg: 'Product not found: ', productId });
        return;
      }
      const tagManager = new EntityManager<ITag>(this.baseURL, '/api/proxy/tags');
      const promisesCreation = tags
        ?.filter((tag) => tag.name)
        ?.map((tag) => new Tag({ ...tag, productId })?.toFormData?.())
        ?.map((tag) => tagManager.create(tag));
      tagsResponse = await Promise.all(promisesCreation);
    } catch (error) {
      LOG.error({ msg: 'Error adding tags to products', error });
    }
    return tagsResponse;
  }

  async addCollection(productId: string, collection: ICollection): Promise<unknown> {
    if (!collection?.name) return;
    let collectionResponse: unknown = null;
    try {
      const product = await this.getById(productId);
      if (!product) {
        LOG.error({ msg: 'Product not found: ', productId });
        return;
      }
      const collectionManager = new EntityManager<ICollection>(this.baseURL, '/api/proxy/collections');
      const newCollection = new Collection(collection as Record<string, unknown>).toFormData();
      const collectionId = collection?.id ? collection?.id : ((await collectionManager.create(newCollection))?.data as ICollection)?.id;
      const updatedProduct = { collectionId };

      collectionResponse = await this.update(productId, updatedProduct);
    } catch (error) {
      LOG.error({ msg: 'Error adding collection to products', error });
    }
    return collectionResponse;
  }

  async addLine(productId: string, line: ILine): Promise<unknown> {
    if (!line?.name) return;
    let lineResponse: unknown = null;
    try {
      const product = await this.getById(productId);
      if (!product) {
        LOG.error('Product not found: ', productId);
        return;
      }
      const lineManager = new EntityManager<ILine>(this.baseURL, '/api/proxy/lines');
      const newLine = new Line(line as Record<string, unknown>).toFormData();
      const lineId = line?.id ? line.id : ((await lineManager.create(newLine))?.data as ILine)?.id;
      const updatedProduct = { lineId };
      lineResponse = await this.update(productId, updatedProduct);
    } catch (e) {
      LOG.error('Error adding line to products', e);
    }
    return lineResponse;
  }

  async addProductCategories(productId: string, categories: ICategory[]): Promise<unknown> {
    if (!categories?.length) return;

    let categoryResponse: unknown = null;

    try {
      const product = await this.getById(productId, { joins: '[categories:category]' });
      const productCategoryManager = new EntityManager<{ id?: string; categoryId?: string; productId?: string }>(this.baseURL, '/api/proxy/product_category');
      const categoryManager = new EntityManager<ICategory>(this.baseURL, '/api/proxy/categories');

      if (!product) {
        LOG.error({ msg: 'Product not found: ', productId });
        return null;
      }

      const exitsCategories = ((product?.data as IProduct)?.categories as { id: string; category: ICategory }[] as Array<ICategory>) || [];

      const promisesDeletion = exitsCategories?.map((productCategory) => productCategoryManager.delete(productCategory?.id || ''));
      await Promise.all(promisesDeletion as Array<Promise<unknown>>);

      const newCategories = categories?.filter((category) => !category?.id && category?.name);

      const promisesCreationOfNewCategories = newCategories?.map((category) => new Category(category as Record<string, unknown>)?.toFormData?.())?.map((category) => categoryManager.create(category));
      const newCategoriesCreated = (await Promise.all(promisesCreationOfNewCategories))?.map((category) => category?.data as ICategory);

      const promisesCreation = categories
        ?.filter((category) => category?.id)
        ?.concat(newCategoriesCreated)
        ?.filter((category) => category?.id)
        ?.map((category) => productCategoryManager.create({ categoryId: category.id, productId }));

      categoryResponse = await Promise.all(promisesCreation);
    } catch (error) {
      LOG.error({ msg: 'Error adding category to products', error });
    }

    return categoryResponse;
  }

  async addCategory(productId: string, category: ICategory): Promise<unknown> {
    if (!category?.name) return;
    let categoryResponse: unknown = null;
    try {
      const product = await this.getById(productId);
      if (!product) {
        LOG.error('Product not found: ', productId);
        return;
      }
      const categoryManager = new EntityManager<ICategory>(this.baseURL, '/api/proxy/categories');
      const newCategory = new Category(category as Record<string, unknown>).toFormData();
      const categoryId = category?.id ? category.id : ((await categoryManager.create(newCategory))?.data as ICategory)?.id;
      const updatedProduct = { categoryId };
      categoryResponse = await this.update(productId, updatedProduct);
    } catch (error) {
      LOG.error('Error adding category to products', error);
    }
    return categoryResponse;
  }

  async addSizes(productId: string, sizes: Array<ISize>): Promise<unknown> {
    if (!sizes?.length) return;
    let sizesResponse: unknown = null;

    try {
      const product = await this.getById(productId, { joins: '[sizes:size]' });

      const prodSizesManager = new EntityManager<{ sizeId?: string; productId?: string; id?: string }>(this.baseURL, '/api/proxy/product_size');
      const sizesManager = new EntityManager<ISize>(this.baseURL, '/api/proxy/sizes');

      if (!product) {
        LOG.error({ msg: 'Product not found: ', productId });
        return;
      }

      const exitsSizes = ((product?.data as IProduct)?.sizes as { size: ISize }[] as Array<ISize>) || [];

      const promisesDeletion = exitsSizes?.map((size) => prodSizesManager.delete(size?.id || ''));
      await Promise.all(promisesDeletion as Array<Promise<unknown>>);

      const newSizes = sizes?.filter((size) => !size?.id && size?.name);
      const promisesCreationOfNewSizes = newSizes?.map((size) => new Size(size as Record<string, unknown>)?.toFormData?.())?.map((size) => sizesManager.create(size));
      const newSizesCreated = (await Promise.all(promisesCreationOfNewSizes))?.map((size) => size?.data as ISize);

      const promisesCreation = sizes
        ?.filter((size) => size?.id)
        ?.concat(newSizesCreated)
        ?.filter((size) => size?.id)
        ?.map((size) => prodSizesManager.create({ sizeId: size.id, productId }));

      sizesResponse = await Promise.all(promisesCreation);
    } catch (error) {
      LOG.error({ msg: 'Error adding sizes to products', error });
    }

    return sizesResponse;
  }

  async addColors(productId: string, colors: Array<IColor>): Promise<unknown> {
    if (!colors?.length) return;
    let colorsResponse: unknown = null;
    try {
      const product = await this.getById(productId, { joins: '[colors:color]' });

      const productColorManager = new EntityManager<{ id?: string; colorId?: string; productId?: string }>(this.baseURL, '/api/proxy/product_color');
      const colorManager = new EntityManager<IColor>(this.baseURL, '/api/proxy/colors');

      if (!product) {
        LOG.error('Product not found: ', productId);
        return;
      }

      const exitsColors = ((product?.data as IProduct)?.colors as { color: IColor }[] as Array<IColor>) || [];
      const promisesDeletion = exitsColors?.map((color) => productColorManager.delete(color?.id || ''));
      await Promise.all(promisesDeletion as Array<Promise<unknown>>);

      const newColors = colors?.filter((color) => !color?.id && color?.name);
      const promisesCreationOfNewColors = newColors?.map((color) => new Color(color as Record<string, unknown>)?.toFormData?.())?.map((color) => colorManager.create(color));
      const newColorsCreated = (await Promise.all(promisesCreationOfNewColors))?.map((color) => color?.data as IColor);

      const promisesCreation = colors
        ?.filter((color) => color?.id)
        ?.concat(newColorsCreated)
        ?.filter((color) => color?.id)
        ?.map((color) => productColorManager.create({ colorId: color.id, productId }));

      colorsResponse = await Promise.all(promisesCreation);
    } catch (error) {
      LOG.error({ msg: 'Error adding colors to products', error });
    }
    return colorsResponse;
  }

  async save(product: Record<string, unknown>): Promise<{ error: string | null; data: IProduct | null }> {
    let data: IProduct | null = null;
    let errorMessage: string | null = null;

    try {
      if (Array.isArray(product.gallery)) {
        const s3UploadBase64Promises = product.gallery?.filter((img: { url: string }) => isBase64(img.url))?.map((img: { url: string }, idx: number) => this.uploadImage(img, product, idx));

        let newGallery = (await Promise.all(s3UploadBase64Promises))?.filter((uploadedBase64) => !!uploadedBase64)?.map((img) => ({ url: img?.host?.concat(img?.path) || img?.path }));

        const existingGallery = product.gallery?.filter((img: { url: string }) => !isBase64(img.url));

        LOG.debug({ msg: 'Gallery to save', newGallery, existingGallery });

        product.gallery = [...existingGallery, ...newGallery];
      }

      if (product?.thumbnail && isBase64((product?.thumbnail as IImage)?.url as string)) {
        const newThumbnail = await this.uploadImage(product?.thumbnail as { url: string }, product, 0);
        product.thumbnail = newThumbnail?.host?.concat(newThumbnail?.path) || newThumbnail?.path;
      }

      const newProduct = new Product(product).toFormData();
      LOG.debug({ msg: 'Product to save', newProduct, product });

      const response = product.id ? await this.update(product.id as string, newProduct) : await this.create(newProduct);
      data = response?.data as IProduct;
      LOG.debug({ msg: 'Product saved', data });

      if (!data.id) {
        errorMessage = 'Error al guardar el producto, no pudimos crear o actualizar.';
        return { data, error: errorMessage };
      }

      if (product.colors) await this.addColors(data.id as string, product.colors as Array<IColor>);
      if (product.sizes) await this.addSizes(data.id as string, product.sizes as Array<ISize>);
      if (product.tags) await this.addTags(data.id as string, product.tags as Array<ITag>);
      if (product.line) await this.addLine(data.id as string, product.line as ILine);
      if (product.collection) await this.addCollection(data.id as string, product.collection as ICollection);
      if (product.categories) await this.addProductCategories(data?.id as string, product.categories as Array<ICategory>);
      if (product.category) await this.addCategory(data?.id as string, product.category as ICategory) as ICategory;

    } catch (error) {
      errorMessage = 'Error al guardar el producto';
      LOG.error({ msg: 'Error al guardar el producto', error, data });
    }
    return { data, error: errorMessage };
  }
}

export default ProductManager;

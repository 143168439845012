import { BaseEntity, IBaseEntity } from '@/bundles/core/model';

export interface ICategory extends IBaseEntity {
  status?: string;
  name?: string;
  slug?: string;
  parentId?: string;
  productId?: string;
}

export class Category extends BaseEntity implements ICategory {
  name?: string = '';
  slug?: string = '';
  parentId?: string;
  productId?: string;

  constructor(arg: Record<string, unknown>) {
    super(arg);
    this.name = (arg?.name as string) || '';
    this.slug = (arg?.slug as string) || this.name;
    this.parentId = arg?.parentId as string;
    this.productId = arg?.productId as string;
  }
}

import type { IBaseEntity } from '@/bundles/core/model';
import { BaseEntity } from '@/bundles/core/model';

export interface ICollection extends IBaseEntity {
  name?: string;
  productId?: string;
}

export class Collection extends BaseEntity implements ICollection {
  name?: string = '';
  productId?: string;

  constructor(arg: Record<string, unknown>) {
    super(arg);
    this.name = (arg?.name as string) || '';
    this.productId = arg?.productId as string;
  }
}

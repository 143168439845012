import { zodResolver } from '@hookform/resolvers/zod';
import LOG from '@lib/logger';
import { useMemo } from 'react';
import { z } from 'zod';

import InputField from '@/bundles/core/components/client/input-field';
import InputSelect from '@/bundles/core/components/client/input-select';
import { IFormField } from '@/bundles/core/types';
import { UserFormProps } from '@/bundles/users/components/client/user-form';
import { IUser, User } from '@/bundles/users/model/user';

import schemas from '../schema';

export type FormValuesProps = z.infer<typeof schemas>;

export type useFormFieldProps = { user?: IUser } & UserFormProps;

export default function useFormFields(props: useFormFieldProps) {
  const fields = useMemo(() => {
    const inputFields: Array<IFormField> = [
      [
        {
          name: 'name',
          label: 'Nombre',
          type: 'text',
          autoComplete: 'name',
          Component: InputField,
        },
        {
          name: 'role',
          label: 'Role',
          type: 'select',
          Component: InputSelect,
          options: [
            { label: 'Usuario', value: 'usuario' },
            { label: 'Admin', value: 'admin' },
          ],
        },
        {
          name: 'status',
          label: 'Estado',
          type: 'select',
          Component: InputSelect,
          options: [
            { label: 'Pendiente', value: 'pendiente' },
            { label: 'Suspendido', value: 'suspendido' },
            { label: 'Activo', value: 'activo' },
          ],
        },
      ],
      {
        name: 'username',
        label: 'Usuario',
        autoComplete: 'username',
        type: 'text',
        Component: InputField,
      },
      {
        name: 'email',
        label: 'Correo electrónico',
        type: 'email',
        autoComplete: 'email',
        Component: InputField,
      },
      [
        {
          name: 'password',
          label: 'Clave',
          type: 'password',
          autoComplete: 'new-password',
          Component: InputField,
        },
        {
          name: 'confirm',
          label: 'Confirmar Clave',
          autoComplete: 'new-password',
          type: 'password',
          Component: InputField,
        },
      ],
    ];
    return inputFields;
  }, []);

  const defaultValues = useMemo(() => {
    const defValues = (props.user ? new User(props.user as Record<string, unknown>) : new User({})) as FormValuesProps;
    LOG.debug({ msg: 'defaultValues', defValues, user: props.user });
    return defValues;
  }, [props.user]);

  const resolver = zodResolver(schemas);

  return { fields, defaultValues, resolver };
}

import { BaseEntity, IBaseEntity } from '@/bundles/core/model';

export interface ILine extends IBaseEntity {
  name?: string;
  groupName?: string;
  productId?: string;
}

export class Line extends BaseEntity implements ILine {
  name?: string = '';
  groupName?: string;
  productId?: string;

  constructor(arg: Record<string, unknown>) {
    super(arg);
    this.name = arg?.name as string;
    this.groupName = arg?.groupName as string;
    this.productId = arg?.productId as string;
  }
}

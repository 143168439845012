import { BaseEntity, IBaseEntity } from '@/bundles/core/model';

export interface IUser extends IBaseEntity {
  email?: string;
  name?: string;
  username?: string;
  password?: string;
  role?: string;
  status?: string;
}

export class User extends BaseEntity implements IUser {
  email?: string = '';
  name?: string = '';
  username?: string = '';
  password?: string = '';
  role?: string = 'usuario';
  status?: string = 'pendiente';

  constructor(arg: Record<string, unknown>) {
    super(arg);
    this.id = (arg?.id as string) || '';
    this.email = (arg?.email as string) || '';
    this.name = (arg?.name as string) || '';
    this.username = (arg?.username as string) || '';
    this.password = (arg?.password as string) || '';
    this.role = (arg?.role as string) || 'usuario';
    this.status = (arg?.status as string) || 'pendiente';
  }
}

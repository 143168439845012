import { BaseEntity, IBaseEntity } from '@/bundles/core/model';

export interface ITag extends IBaseEntity {
  name?: string;
  slug?: string;
  productId?: string;
}

export class Tag extends BaseEntity implements ITag {
  name?: string = '';
  slug?: string = '';
  productId?: string;

  constructor(arg: Record<string, unknown>) {
    super(arg);
    this.name = (arg?.name as string) || '';
    this.slug = (arg?.value as string) || this.name;
    this.productId = arg?.productId as string;
  }
}
